import React, { useContext } from 'react'
import Dashboard from '../components/Dashboard/Dashboard'
import Card from 'components/UI/Card'
import AuthContext from 'store/auth-context'

const Home: React.FC = () => {
  const authCtx = useContext(AuthContext)

  if (authCtx.user.roles.length === 1 && authCtx.user.roles[0] === 'opticien_magasin') {
    return <></>
  }

  return (
    <Card>
      <Dashboard />
    </Card>
  )
}

export default Home
