import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ModalOrderProducts from 'components/Modal/ModalOrderProducts'
import AuthContext from 'store/auth-context'
import { ToastContext } from 'store/toast-context'
import ResultsTable from 'components/UI/ResultsTable'
import { constants } from 'utils/constants'
import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import Card from 'components/UI/Card'
import { extractPhoneNumberFromContactInfo } from 'utils/functions'
import { stringsToJSXs } from 'utils/functions'
import DOButton from 'components/Modal/DOButton'
import DOModal from 'components/Modal/DOModal'
import { GET_ORDER_LISTING } from 'graphql/queries/getOrderListing'
import GET_ORDER_INFO from 'graphql/queries/getDOOrderInfo'

interface Props {}

const EnregistrementReception: React.FC<Props> = () => {
  const authCtx = useContext(AuthContext)
  const { t } = useTranslation()

  const { toast } = useContext(ToastContext)

  const [isDisabled, setIsDisabled] = useState(false)

  const storeId = parseInt(authCtx.user.storeId)

  const [showProductsModal, setShowProductsModal] = useState(null)

  const { loading, error, data } = useQuery(GET_ORDER_LISTING, {
    variables: {
      filter: `{ "$and": [{"deliveryStore__id": "${storeId}"}, {"workflowState":{"$notlike":"completed"}}]}`,
    },
  })

  const client = useApolloClient()

  const checkReceptionMagasin = (order: orderType): boolean => {
    let addOrder = false
    if (
      [
        'in_verification',
        'awaiting_preparation',
        'in_preparation',
        'delivery_in_progress',
      ].includes(order.workflowState)
    ) {
      addOrder = true
      if (order.workflowState === 'delivery_in_progress') {
        const arrivedAtStoreEvents = order.events.filter(
          (event: orderEvent) =>
            event.closureDate === null &&
            event.eventType.key ===
              constants.eventTypeKeys.ORDER_EVENT_TYPE_ARRIVED_AT_STORE
        )
        if (arrivedAtStoreEvents.length > 0 || order.events.length === 0)
          addOrder = false
      }
    }
    return addOrder
  }

  const [createDOOrderEvent] = useMutation(CM_CREATE_DO_ORDER_EVENT, {
    onCompleted: async (data) => {
      try {
        const { data: dataOrder } = await client.query({
          query: GET_ORDER_INFO,
          variables: {
            id: +data.CMCreateDOOrderEvent.output.orderEvent.order.id,
          },
        })
        toast({
          title: 'Arrivée au magasin :',
          message: `Commande n°${dataOrder.CQgetOrder.edges[0].node.order.key}`,
        })
      } catch (error) {
        console.error('Erreur lors de la récupération des informations sur la commande :', error)
        toast({
          title: 'Erreur',
          message: "Impossible de récupérer les informations sur la commande.",
        })
      } finally {
        setIsDisabled(false)
      }
    },
    onError: () => {
      setIsDisabled(false)
    },
  })

  if (loading)
    return (
      <Card>
        <SkeletonBase count={6} />
      </Card>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const jsonData = JSON.parse(JSON.stringify(data))

  jsonData.getOrderListing.edges.forEach((item: any) => {
    let order = item.node.order

    let calculators = item.node.calculators
    order.fullName =
      order.deliveryName !== null
        ? order.deliveryName[0].firstName + ' ' + order.deliveryName[0].lastName
        : '-'

    order.phone = extractPhoneNumberFromContactInfo(order.deliveryAddress)

    order.translatedWorkflowState = t(order.workflowState)

    order.orderKey = order.key

    order.products = (
      <>
        <div>
          <DOButton
            className="btn-primary"
            text="Voir"
            handleClick={() => setShowProductsModal(order.id)}
          />
          <DOModal
            show={showProductsModal === order.id}
            customSize="modal-647"
            closeModal={() => setShowProductsModal(null)}
          >
            <ModalOrderProducts
              orderId={order.id}
              orderKey={order.key}
              fullName={order.fullName}
              phone={order.phone}
            />
          </DOModal>
        </div>
      </>
    )

    order.arrivedAtStore = (
      <button
        className="btn btn-primary"
        onClick={() => {
          if (
            window.confirm(
              'Êtes-vous sûr d’avoir reçu l’intégralité de la commande client (' +
                t('product', { count: calculators.productCount }) +
                ') ?'
            )
          )
            confirmReception(order)
        }}
        disabled={
          order.workflowState === 'delivery_in_progress' ? isDisabled : true
        }
      >
        Oui
      </button>
    )
  })

  const confirmReception = async (order: orderType) => {
    setIsDisabled(true)
    try {
      await createDOOrderEvent({
        variables: {
          comment: `Arrivée au magasin ${order.deliveryStore.label} (${order.deliveryStore.id})`,
          orderId: +order.id,
          eventTypeKey:
            constants.eventTypeKeys.ORDER_EVENT_TYPE_ARRIVED_AT_STORE,
        },
      })
    } catch (e) {
      // NB : gestion erreur dans App.tsx
    }
  }

  return (
    <div>
      {data && (
        <Card>
          <ResultsTable
            queryType="Commandes en Réception"
            items={jsonData.getOrderListing.edges.filter((item: any) => {
              return checkReceptionMagasin(item.node.order)
            })}
            columns={stringsToJSXs(
              [
                'orderKey',
                'cosiumId',
                'fullName',
                'translatedWorkflowState',
                'products',
                'arrivedAtStore',
                'orderPaidDate',
                'orderSentToStoreDate',
                'phone'
              ],
              t
            )}
          />
        </Card>
      )}
    </div>
  )
}

export default EnregistrementReception
