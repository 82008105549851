import Card from 'components/UI/Card'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import React, { useMemo, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Ajv from 'ajv'
import Papa from 'papaparse'
import { headers, schema } from './ImportProduitConstants'
import { baseStyle, acceptStyle, rejectStyle } from './ImportProduitStyles'
import DownloadLink from 'react-download-link'
import { useMutation } from '@apollo/client'
import IMPORT_PRODUCTS from 'graphql/mutations/ImportProducts'
import {
  commaToArray,
  commaToPoint,
  addImageObject,
} from './ImportProduitFunctions'
import { headersTypeNumber, headersTypeArray } from './ImportProduitConstants'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
interface Props {}

const ImportProduit: React.FC<Props> = () => {
  const [showSuccess, setShowSuccess] = useState(true)
  const [showDanger, setShowDanger] = useState(true)
  const [showWarning, setShowWarning] = useState(true)
  const [myFiles, setMyFiles] = useState<any>('')
  const [fileErrors, setFileErrors] = useState<{ [key: number]: string[] }>({})
  const [finalData, setFinalData] = useState<any[]>([])
  const [warning, setWarning] = useState<string>('')
  const [headerIndex, setHeaderIndex] = useState(0)
  const [realCount, setRealCount] = useState(0)
  const [mutationsData, setMutationsData] = useState({
    creationSuccessLines: [] as any[],
    creationFailInfo:[] as any[],
    creationWarningInfo:[] as any[]
  })
  const [showErrorList, setShowErrorList] = useState(false)
  const [showWarningList, setShowWarningList] = useState(false)
  const [successList, setShowSuccessList] = useState(false)


  const [importProducts, { loading }] =
    useMutation(IMPORT_PRODUCTS, {
      onCompleted: (mutationData) => {
        const successInfo = mutationData.importProducts.output.creationSuccessLines.length? mutationData.importProducts.output.creationSuccessLines:[]
        const failInfo = mutationData.importProducts.output.creationFailInfo.length? mutationData.importProducts.output.creationFailInfo:[]
        const warningInfo= mutationData.importProducts.output.creationWarningInfo.length? mutationData.importProducts.output.creationWarningInfo:[]
        if(failInfo.length){
          failInfo.forEach((item:any) => {
            item.line += realCount
          })
        }
        if(warningInfo){
          warningInfo.forEach((item:any) => {
            item.line += realCount
          })
        }
        if(successInfo.length){
          successInfo.forEach((item:any) => {
            item.line += realCount
          })
        }
        const newMutationsData = {
          creationSuccessLines: mutationsData.creationSuccessLines.concat(successInfo),
          creationFailInfo : mutationsData.creationFailInfo.concat(failInfo),
          creationWarningInfo : mutationsData.creationWarningInfo.concat(warningInfo)
        }
        setMutationsData(newMutationsData)
      }
    })

  const onDrop = useCallback((acceptedFiles) => {
    setWarning('')
    setFileErrors({})
    setFinalData([])
    setMutationsData({
      creationSuccessLines:[] as any,
      creationFailInfo:[] as any,
      creationWarningInfo:[] as any
    })
    setRealCount(0)
    const acceptedFile = acceptedFiles[0]
    if (acceptedFile) {
      setMyFiles(acceptedFile)
      Papa.parse(acceptedFiles[0], {
        encoding:'ISO-8859-1',
        header: false,
        transform: function (value, index: number) {
          return { [headers[index]]: value }
        },
        skipEmptyLines: true,
        delimiter: ';',
        complete: (results) => {
          const parsedData: any[] = results.data
          //check if first line is header (saleId and parentCategoryId isNaN)
          const headerOffset = parsedData[0]?.some((item: any) => {
            return isNaN(item.saleId) && isNaN(item.parentCategoryId);
          })?2:1;
          if(headerOffset===2){
            //remove header
            results.data.shift()
            setHeaderIndex(1)
          }
          const ajv = new Ajv({ allErrors: true })
          const validate = ajv.compile(schema)
          let jsonDataString: any[] = []
          const lessThan30 = parsedData.filter((arr) => arr.length < 30)
          if (lessThan30.length < 5) {
            // console.log(parsedData)
            const checkRequiredListOfInteger = (property:number[], propertyName:string, index:number) => {
              if (property[0] === 0) {
                setFileErrors((prev) => ({
                  ...prev,
                  [index]: prev[index]?
                  [...prev[index], `La propriété '${propertyName}' est obligatoire`]:
                  [`La propriété '${propertyName}' est obligatoire`],
                }))
              }
            }
            for (let j = 0; j < parsedData.length; j++) {
              let isGtinFormat = false
              if (parsedData[j].length >= 30) {
                jsonDataString[j] = parsedData[j].reduce(
                  (accumulator: any, currentValue: any) => {
                    return Object.assign(accumulator, currentValue)
                  },
                  {}
                )
                jsonDataString[j] = commaToPoint(
                  jsonDataString[j],
                  headersTypeNumber
                )
                jsonDataString[j] = commaToArray(
                  jsonDataString[j],
                  headersTypeArray
                )
                jsonDataString[j] = addImageObject(jsonDataString[j])

                //Check if gtin is written in scientific format
                if (
                  jsonDataString[j].gtin &&
                  jsonDataString[j].gtin.match(/e/i)
                ) {
                  isGtinFormat = true
                }

                // Validate the JSON data against the JSON schema
                const valid = validate(jsonDataString[j])
                const isAccessory = [22,23].includes(+jsonDataString[j].parentCategoryId)
                // check mandatory list of integer
                checkRequiredListOfInteger(jsonDataString[j].parentColorId, 'color_parent_label', j + headerOffset) //parentColorId
                if (!isAccessory) {
                  // check mandatory list of integer
                  checkRequiredListOfInteger(jsonDataString[j].DOAttribute_matiere, 'matiere', j + headerOffset) //DOAttribute_matiere
                  checkRequiredListOfInteger(jsonDataString[j].DOAttribute_forme, 'monture_forme', j + headerOffset)//DOAttribute_forme
                  checkRequiredListOfInteger(jsonDataString[j].DOAttribute_genre, 'genre', j + headerOffset)//DOAttribute_genre
                
                  //check mandatory dimensions
                  const mandatoryDimensions = ['branchLength', 'lensWidth', 'bridgeWidth', 'totalWidth', 'lensHeight']
                  mandatoryDimensions.forEach((dimension) => {
                    if (!jsonDataString[j][dimension] || +jsonDataString[j][dimension] <= 0) {
                        const errorMessage = `La propriété '${dimension}' doit être un nombre supérieur à 0`
                        setFileErrors(prev => ({
                            ...prev,
                            [j + headerOffset]: [...(prev[j + headerOffset] ?? []), errorMessage]
                        }))
                    }
                  })
                }
                if(!isNaN(jsonDataString[j].basePrice) && !isNaN(jsonDataString[j].salePrice)){
                  if(jsonDataString[j].salePrice>jsonDataString[j].basePrice){
                    setFileErrors((prev) => ({
                      ...prev,
                      [j+headerOffset]: [...(prev[j+headerOffset]??[]), 'salePrice (DISCOUNT_PRICE) doit être inférieur au basePrice (ITEM_SALE_PRICE)']
                    }))
                  }
                }
                if (!valid || isGtinFormat) {
                  let errorsText = validate.errors?.map((error) => {
                    //  console.log(error)
                    if (error.keyword === 'required') {
                      error.message = `Le champ '${error.instancePath.replace(
                        '/',
                        ''
                      )}' est obligatoire`
                    } else if (
                      error.keyword === 'type' &&
                      error.params.type === 'integer'
                    ) {
                      error.message = `La propriété '${error.instancePath.replace(
                        '/',
                        ''
                      )}' doit être un entier`
                    } else if (
                      error.keyword === 'type' &&
                      error.params.type === 'number'
                    ) {
                      error.message = `La propriété '${error.instancePath.replace(
                        '/',
                        ''
                      )}' doit être un numéro`
                    } else if (
                      error.keyword === 'type' &&
                      error.params.type === 'string'
                    ) {
                      error.message = `La propriété '${error.instancePath.replace(
                        '/',
                        ''
                      )}' doit être une chaîne de caractères`
                    }
                    return error.message
                  })
                  if (isGtinFormat) {
                    errorsText = errorsText
                      ? [
                          ...errorsText,
                          "Le gtin de produit n'est pas dans le bon format",
                        ]
                      : ["Le gtin de produit n'est pas dans le bon format"]
                  }
                  setFileErrors((prev) => ({
                    ...prev,
                    [j+headerOffset]: [...(prev[j+headerOffset]??[]), ...errorsText as string[]]
                  }))
                } 
                  setFinalData((prev) => [...prev, jsonDataString[j]])
              } else {
                setFileErrors((prev) => ({
                  ...prev,
                  [j+headerOffset]: [...(prev[j+headerOffset]??[]), 'le produit manque un ou plusieurs champs']
                }))
              }
            }
          } else setWarning("Le fichier n'est pas valide")
        },
      })
    }
  }, [])

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    // multiple: false,
    // maxFiles: 1,
    accept: {
      'text/csv': ['.csv'],
    },
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject]
  )

  // Handle delete files
  const removeFile = (file: any) => () => {
    setMyFiles('')
    setFinalData([])
    setShowDanger(false)
    setShowSuccess(false)
    setShowWarning(false)
  }

  const sendMutations = async (array:any[], n:number) => {
    // chunk array into sub array of n size 
    const chunkArr = (arr:any[], size:number) => arr.reduceRight((r,i,_,s) => 
    {return (r.push(s.splice(0,size)),r)},
    [])
    const chunkData =  chunkArr([...array], n)
    // send mutations one after one
    for (const chunk of chunkData) {
      const jsonDataString = JSON.stringify(chunk);
      if (!loading) {
        await importProducts({
          variables: { jsonData: jsonDataString }
        });
        setRealCount((prevCount)=> prevCount + n)
      }
    }
  }
  // Handle mutation
  const handleImport = async () => {
    try {
      setRealCount(0)
      setMutationsData({
        creationSuccessLines:[] as any,
        creationFailInfo:[] as any,
        creationWarningInfo:[] as any
      })
      //show alert
      setShowDanger(true)
      setShowSuccess(true)
      setShowWarning(true)
      //hide list
      setShowSuccessList(false)
      setShowWarningList(false)
      setShowErrorList(false)
      // const jsonDataString: string = JSON.stringify(finalData)
      // await importProducts({
      //   variables: { jsonData: jsonDataString }
      // })
      console.log(finalData.length)
      await sendMutations(finalData,30)

    } catch (e) {
      console.error(e)
    }
  }

  // Display files
  const files = (
    <li>
      <DownloadLink
        label={myFiles?.name}
        filename={myFiles?.name}
        exportFile={() => myFiles}
      />
      <Button
        variant="link"
        size="sm"
        style={{ color: 'red', textDecoration: 'none' }}
        onClick={removeFile(myFiles)}
      >
        Supprimer
      </Button>
      {Object.keys(fileErrors).length ?(
        <div>
          <h4>Erreurs:</h4>
          <ul>
            {Object.keys(fileErrors).map((key)=>{
               return <li key={key}>
                Ligne {key}:
                <ul>
                  {fileErrors[+key]?.map((error: any, index: number) => (
                    <li key={index}>
                      {error}
                    </li>
                  ))}
                </ul>
              </li>
              })}
          </ul>
        </div>
      ): (
        <div></div>
      )}
    </li>
  )

  const handleDownload = (type:number) => {
    let output = ''
    let fileName =''
    if(type===0){
      mutationsData.creationFailInfo?.forEach(
        (item: any) => {
          output += `Ligne: ${item.line+headerIndex}\nDétails de blocage: ${item.blockingInfo}\n\n`
        }
      )
      fileName = 'Erreurs.txt'
    } else if(type===1){
      mutationsData.creationWarningInfo?.forEach(
        (item: any) => {
          output += `Ligne: ${item.line+headerIndex}\n${item.warningInfo}\n\n`
        }
      )
      fileName = 'Produits_deja_existants.txt'
    }
    else{
       mutationsData.creationSuccessLines?.forEach(
        (item: any) => {
          output += `Ligne: ${item.line+headerIndex}\n${item.successInfo}\n\n`
        }
      )
      fileName = 'Produits_importes.txt'
    }
    const dataBlob = new Blob([output], {
      type: 'text/plain',
    })

    const url = URL.createObjectURL(dataBlob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    link.click()
  }
  //create Table Alert 
  const getTableAlert = (title:string, mutationsDataArray: any[], infoType:string) => {
    return (<Table>
      <thead>
        <tr>
          <th>Ligne</th>
          <th>{title}</th>
        </tr>
      </thead>
      <tbody>
          {mutationsDataArray.length>0 && 
          mutationsDataArray?.slice(0, 20)
          .map((item: any) => {
            if(Array.isArray(item[infoType]) && item[infoType].length>1) 
              item[infoType] = item[infoType].join(', ')
            return(
            <tr key={item.line}>
              <td>{+item.line+headerIndex}</td>
              <td>{item[infoType]}</td>
            </tr>
          )}
          )}
      </tbody>
    </Table>)
  }

  return (
    <div>
      <Card>
        <div className="container">
          {fileRejections.length !== 0 && (
            <p style={{ color: 'red', fontSize: '12px', margin: '0' }}>
              Le fichier n'est pas dans le bon format
            </p>
          )}
          {warning && (
            <p style={{ color: 'red', fontSize: '12px', margin: '0' }}>
              {warning}
            </p>
          )}
          <p><b>Attention, le fichier doit contenir une ligne d'en-têtes, sinon le premier produit ne sera pas importé.</b></p>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Déposer un fichier ici (Format CSV) </p>
            <Button variant="primary" type="button" onClick={open}>
              Parcourir
            </Button>
          </div>
          <div>
            {myFiles && myFiles?.length !== 0 && (
              <h4>Les fichiers téléchargés</h4>
            )}
            {myFiles && myFiles?.length !== 0 && files}
          </div>
        </div>
      </Card>
      
      {mutationsData.creationWarningInfo.length > 0 && showWarning && (
          <Alert
            variant="warning"
            onClose={() => {if(!loading)setShowWarning(false)}}
            dismissible
          >
            <Alert.Heading className='d-flex justify-content-between'>
             <div>Produits déjà existants: {mutationsData.creationWarningInfo.length}/{finalData.length}{' '}
             {showWarningList ?<ChevronUpIcon onClick={() => setShowWarningList(false)} className="header-title-icon"/>  :
             <ChevronDownIcon onClick={() => setShowWarningList(true)} className="header-title-icon"/>}
             </div>
              <Button
                variant="outline-warning"
                size="sm"
                onClick={() => handleDownload(1)}
              >
                Télécharger les déjà existants
              </Button>{' '}
            </Alert.Heading>
            {showWarningList && getTableAlert('Produit', mutationsData.creationWarningInfo, 'warningInfo')}
          </Alert>
      )}
      {mutationsData.creationSuccessLines.length > 0 &&
        showSuccess && (
          <Alert
            variant="success"
            onClose={() => {if(!loading)setShowSuccess(false)}}
            dismissible
          >
            <Alert.Heading className='d-flex justify-content-between'>
              <div>L'importation des produits a réussi {mutationsData.creationSuccessLines.length}/{finalData.length}{' '}
              {successList ?<ChevronUpIcon onClick={() => setShowSuccessList(!successList)} className="header-title-icon"/>  :
              <ChevronDownIcon onClick={() => setShowSuccessList(!successList)} className="header-title-icon"/>}
             </div>
              <Button
                variant="outline-success"
                size="sm"
                onClick={() => handleDownload(2)}
              >
                Télécharger tous les succès
              </Button>{' '}

            </Alert.Heading>
            {successList && getTableAlert('Produit', mutationsData.creationSuccessLines, 'successInfo')}
          </Alert>
        )}
      {
        (mutationsData.creationFailInfo.length>0 && showDanger 
          && (
          <Alert
            variant="danger"
            onClose={() => {if(!loading)setShowDanger(false)}}
            dismissible
          >
            <Alert.Heading className='d-flex justify-content-between'>
              <div>
                Produits en échec d'import {mutationsData.creationFailInfo.length}/{finalData.length}{' '}
                {showErrorList ?<ChevronUpIcon onClick={() => setShowErrorList(false)} className="header-title-icon"/>  :
                <ChevronDownIcon onClick={() => setShowErrorList(true)} className="header-title-icon"/>}
            </div>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => handleDownload(0)}
              >
                Télécharger toutes les erreurs
              </Button>{' '}
            </Alert.Heading>
            {showErrorList && getTableAlert('Détails de blocage', mutationsData.creationFailInfo, 'blockingInfo')}
          </Alert>
        ))}
      <div className="position-fixed p-2 end-0 bottom-0 me-5 mb-5">
        <Button
          disabled={finalData.length === 0||loading}
          variant={`${loading?'danger':'primary'} shadow zindex-2 btn-radius `}
          type="button"
          onClick={handleImport}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Importer'
          )}
        </Button>
      </div>
    </div>
  )
}

export default ImportProduit
