import React, {useMemo, MouseEvent, useState, useRef, useEffect, useCallback} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTable, useSortBy } from 'react-table'
import InfiniteScroll from 'react-infinite-scroll-component'
import { QueryLazyOptions, OperationVariables } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import classes from "./SortableResultsTable.module.css"
import { DotsCircleHorizontalIcon } from '@heroicons/react/solid'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import DOModal from 'components/Modal/DOModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
interface Props {
  tableColumns: any[]
  tableData: any[]
  dataTotalSize: number
  onClickRows?: (orderId: string) => void
  fetchMoreData: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void
  displayLineNumbers?: boolean
  tableType?: string
  eventTypeLabel?: string
  nbOfNextColumnsToCopy?: number
}
const SortableResultsTable: React.FC<Props> = ({
  tableColumns,
  tableData,
  dataTotalSize,
  onClickRows,
  fetchMoreData,
  displayLineNumbers,
  tableType,
  eventTypeLabel,
  nbOfNextColumnsToCopy
}) => {

  const [commentModalData, setCommentModalData] = useState<any>({})
  const commentElements = useRef(new Array<HTMLDivElement>())
  const data = useMemo(() => tableData, [tableData])

  const handleCommentClick = useCallback((e: MouseEvent) => {
    e.stopPropagation()
    // Get the data for the current row
    const id = e.currentTarget.parentElement?.parentElement?.parentElement?.getAttribute('id')
    setCommentModalData(data.find((element: any) => element.oo_id === id))
  }, [data])

  const getColumnTextToCopyBis = useCallback((el: Element | null | undefined, n: number, nbCol: number) : string => {
    if(n < nbCol)
      return [el?.querySelector('.text-center')?.innerHTML ?? '', getColumnTextToCopyBis(el?.nextElementSibling, ++n, nbCol)].join(' ')
    return '';
  },[])

  const getColumnTextToCopy = useCallback((e: Element, nbCol: number | undefined) : string => {
    return getColumnTextToCopyBis(e.parentElement?.nextElementSibling, 0, nbCol === undefined ? 1 : nbCol).trimEnd()
  }, [getColumnTextToCopyBis])

  const copyToClipboard = useCallback(async (e: MouseEvent) => {
    e.stopPropagation()
    // For some reason, currentTarget is null after the awaited function
    const currentElement = e.currentTarget
    await navigator.clipboard.writeText(getColumnTextToCopy(e.currentTarget, nbOfNextColumnsToCopy))
    // Create tooltip, display 1s and remove
    const d = document.createElement('div')
    d.classList.add(classes.copyTooltip)
    d.innerHTML = 'Copié'
    currentElement?.appendChild(d)
    setTimeout(() => d.remove(), 1000)
  },[getColumnTextToCopy, nbOfNextColumnsToCopy])

  const columns = useMemo(() =>{
    //this function is formatting tableColumns  :
    // add index to tableColumns 
    /*
     * NB: expecting tableColumns to be like
      [{
        headerName: TRANSLATED_HEADER_NAME,
        accessor: ACCESSOR_VARIABLE_NAME,
        disableSortBy: TRUE_OR_FALSE,
      },]
     */
    let tableColumnsFormatted = []
    // Putting index number in table's first column to be aware of how many rows are displayed
    // Added the possibility of hiding line numbers with property displayLineNumbers to false
    if(typeof displayLineNumbers === 'undefined' || displayLineNumbers)
      tableColumnsFormatted.push({
        Header: '',
        accessor: 'id',
        Cell: (cell: any) => (
          <div className="text-center text-black-50 fw-lighter">
            {cell.row.index + 1}
          </div>
        ),
      })
    for (const tableColumn of tableColumns) {
      tableColumnsFormatted.push({
        Header: tableColumn.headerName,
        accessor: tableColumn.accessor,
        Cell: (row: any) => {
          return (tableType === 'monitoring' && tableColumn.headerName === 'Commentaire' ? 
            <>
              <div className="comment-simple text-center" ref={(element: HTMLDivElement) => commentElements.current.push(element)}>{row.value}</div>
              <div className="comment-overlay" style={{display: 'none'}}>
                <OverlayTrigger overlay=
                  {
                    <Tooltip>{row.value}</Tooltip>
                  }
                >
                  <div className={classes.commentWrapper}><div className={'text-center ' + classes.twoLinesComment}>{row.value}</div>
                  <DotsCircleHorizontalIcon onClick={handleCommentClick} className={classes.iconSize}></DotsCircleHorizontalIcon></div>
                </OverlayTrigger>
              </div>
            </> : 
            <>
              {tableColumn.headerName === 'Copie' ? 
              <div className="text-center" style={{position: 'relative'}} onClick={copyToClipboard}>
              <FontAwesomeIcon
                icon={faCopy}
                style={{ cursor: 'pointer' }}
              /></div> : <div className="text-center">{row.value}</div>}
            </>)},
        disableSortBy: tableColumn.disableSortBy,
      })
    }
    return tableColumnsFormatted
  },[tableColumns, displayLineNumbers, tableType, handleCommentClick, copyToClipboard])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    const context: CanvasRenderingContext2D | null = canvas.getContext('2d')
    if(context)
    {
      context.font = '1rem system-ui'
      commentElements.current.forEach((element) => {
        if(element && element.clientWidth > 0 && context?.measureText(element.innerHTML).width > 2 * element.clientWidth)
        {
          element.style.display = 'none'
          const overlayElement: HTMLElement | null | undefined = element.parentElement?.querySelector('.comment-overlay')
          if(overlayElement)
            overlayElement.style.display = 'block'
        }
      })
    }
    canvas.remove()
  }, [commentElements, data])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy)
  return (
    <>
      {/* Remarque : Le scroll infini peut ne pas fonctionner si la page contient un élément qui est plus long que le tableau */}
      <InfiniteScroll
        dataLength={rows.length}
        next={fetchMoreData}
        hasMore={rows.length < dataTotalSize}
        loader={<SkeletonBase count={2} />}
      >
        <table
          {...getTableProps()}
          className="table table-striped table-hover w-100"
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup: any) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column: any) => (
                      // Apply the header cell props
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="text-center"
                      >
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row: any) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    onClick={() => {
                      onClickRows && onClickRows(row.original.oo_id)
                    }}
                    style={{
                      cursor: onClickRows ? 'pointer' : 'inherit',
                    }}
                    id={row.original?.oo_id}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell: any) => {
                        // Apply the cell props
                        return (
                          <td className={classes.tableRow + (tableType === 'monitoring' && cell.column.Header === 'Commentaire' ? ' ' + classes.comment : '') + (+row.original.daysSinceEventCreated>4? " text-danger":"")} {...cell.getCellProps()} id={row.original.oo_id}>
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </InfiniteScroll>
      <DOModal
        show={Object.keys(commentModalData).length > 0}
        customSize="modal-647"
        closeModal={() => setCommentModalData({})}
        closeOnExternalClick={true}
      >
        <>
          <h4><InformationCircleIcon className={classes.informationIconSize}></InformationCircleIcon> {commentModalData.oo_key} - {eventTypeLabel}</h4>
          <Container>
            <Row><Col xs={4}><b>Pays :</b></Col><Col>{commentModalData.country}</Col></Row>
            <Row><Col xs={4}><b>Date :</b></Col><Col>{commentModalData.o_creationDate}</Col></Row>
            <Row><Col xs={4}><b>Nom :</b></Col><Col>{commentModalData.customer_full_name}</Col></Row>
            <Row><Col xs={4}><b>Depuis (j) :</b></Col><Col>{commentModalData.last_event}</Col></Row>
            <Row><Col xs={4}><b>Commentaire :</b></Col><Col>{commentModalData.comment}</Col></Row>
            <Row><Col xs={4}><b>Créé par :</b></Col><Col>{commentModalData.user_login_create}</Col></Row>
            <Row><Col xs={4}><b>Montant :</b></Col><Col>{commentModalData.total_amount}</Col></Row>
          </Container>
        </>
      </DOModal>
    </>
  )
}

export default SortableResultsTable
