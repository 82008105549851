import gql from 'graphql-tag'

const GET_ORDER_BY_EVENT_TYPE_NEW_LISTING = gql`
  query GET_ORDER_BY_EVENT_TYPE_NEW_LISTING(
    $orderEventTypeId: Int
     #TODO ? $orderEventTypeKeys: [String]
    $limit: Int
    $offset: Int
    $siteFilter: String
    $fromDate: String
    $toDate: String
  ) {
    getDOOrderByEventTypeNewListing(
      orderEventTypeId: $orderEventTypeId
      #TODO ? orderEventTypeKeys: $orderEventTypeKeys
      limit: $limit
      offset: $offset
      siteFilter: $siteFilter
      fromDate: $fromDate
      toDate: $toDate
    ) {
      edges {
        node {
          id
          eventCreationDate
          eventLabel
          eventComment
          eventTypeId
          eventTypeKey
          orderId
          orderKey
          orderCreationDate
          site
          customerFullname
          totalAmount
          userLoginCreate
        }
      }
      orderEventType {
        ... on object_DOOrderEventType {
          id
          key
          eventFamily
          label
        }
      }
      totalCount
      totalOrders
    }
  }
`

export default GET_ORDER_BY_EVENT_TYPE_NEW_LISTING
